import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import { IconDateSmall } from '../icons/IconDateSmall';
import './DatePicker.css';

export type DatePickerInputProps = {
  format?: string;
  value: Date | null;
  showTimeInput?: boolean;
  inputClassName?: string;
  className?: string;
  showIcon?: boolean;
  icon?: React.ReactNode;
  outline?: boolean;
  isDisabled?: boolean;
  minDate?: Date | null;
  excludeDates?: Date[];
  monthsShown?: number;
  startDate?: Date | null;
  endDate?: Date | null;
  onChange: (date: Date) => void;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  placeholder?: string;
  hasError?: boolean;
  customInput?: React.ReactNode;
};

const CalendarWrapper: FC<React.PropsWithChildren<{ className?: string }>> = ({ className, children }) => {
  return (
    <div className="tw-w-full">
      <CalendarContainer className={className}>
        <div className="tw-relative">{children}</div>
      </CalendarContainer>
    </div>
  );
};

export const DatePickerInput: FC<React.PropsWithChildren<DatePickerInputProps>> = ({
  format,
  showTimeInput,
  value,
  onChange,
  isDisabled,
  outline,
  inputClassName,
  minDate,
  className,
  excludeDates,
  monthsShown = 1,
  showIcon = false,
  icon,
  endDate,
  startDate,
  selectsStart = false,
  selectsEnd = false,
  placeholder,
  hasError,
  customInput,
}) => {
  return (
    <div
      className={classNames([className, showIcon && 'tw-flex tw-items-center tw-justify-between', monthsShown <= 1 && 'tw-relative', 'tw-text-cool-gray-400'])}
    >
      <DatePicker
        className={classNames([
          inputClassName,
          hasError ? 'tw-border-red-300' : 'tw-border-cool-gray-200 ',
          outline && 'tw-p-3 tw-border tw-rounded-md',
          showIcon && 'tw-z-10 tw-relative',
          isDisabled && 'tw-bg-cool-gray-100',
          'tw-text-blue-gray-900 tw-max-w-6xl tw-w-full tw-py-3 tw-bg-transparent tw-outline-none',
        ])}
        selected={value}
        onChange={onChange}
        calendarContainer={CalendarWrapper}
        dateFormat={format}
        startDate={startDate}
        endDate={endDate}
        disabled={isDisabled}
        showTimeInput={showTimeInput}
        minDate={minDate}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        placeholderText={placeholder}
        monthsShown={monthsShown}
        excludeDates={excludeDates}
        customInput={customInput}
        calendarClassName="tw-w-full tw-p-2.5 tw-border-0 tw-rounded-md tw-text-xs tw-text-red-100 tw-shadow tw-bg-white"
      />
      {showIcon && <div className="tw-right-4 tw-absolute tw-z-0">{icon ?? <IconDateSmall />}</div>}
    </div>
  );
};
