import React, { FC } from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

type Props = {
  /**
   * @deprecated
   * use `hasNoMargin`
   */
  hasMargin?: boolean;
  hasNoMargin?: boolean;

  fontWeight?: 'medium' | 'semibold' | 'bold';
  seoTitle?: string;
  className?: string;
  testId?: string;
};

export const PageTitle: FC<React.PropsWithChildren<Props>> = ({
  children,
  hasMargin = true,
  hasNoMargin = false,
  fontWeight = '',
  seoTitle,
  className = '',
  testId,
}) => (
  <h1
    data-testid={testId}
    className={classNames([
      className,
      'tw-text-2xl tw-text-blue-gray-900',
      hasNoMargin ? 'tw-mb-0' : hasMargin ? 'tw-mb-8' : 'tw-mb-0',
      fontWeight ? `tw-font-${fontWeight}` : 'tw-font-bold',
    ])}
  >
    {seoTitle && (
      <Helmet>
        <title>{`GoParrot | ${seoTitle}`}</title>
      </Helmet>
    )}
    {children}
  </h1>
);
